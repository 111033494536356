/* eslint-disable react/prop-types */
import {
  EmailAuthProvider,
  deleteUser,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth } from "lib/firebase/firebase";
import { trackEvent } from "lib/firebase/firebaseWrapper";
import { techyrrCompanyId } from "lib/utilities/globalConstants";
import { BASE_URL } from "lib/utilities/globalConstants";
import { interviewTemplatesV1, interviewTemplatesV2 } from "lib/utilities/templates";
import mixpanel from "mixpanel-browser";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const AuthContext = createContext();

const unauthLocations = [
  "/signup",
  "/forgot-password",
  "/admin/signup/*",
  "/techyrr-admin/signup/*"
];

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [isJobFormOpen, setIsJobFormOpen] = useState(false);
  const [isDraftsOpen, setIsDraftsOpen] = useState(false);
  const [useDefaultTemplates, setUseDefaultTemplates] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const formDataInitialState = {
    skills: [],
    jobRole: "",
    experience: "",
    candidates: [],
    companyName: "",
    adminId: "",
    adminName: "",
    oldSkills: [],
    newSkills: [],
    templatesV1: interviewTemplatesV1(),
    templatesV2: interviewTemplatesV2(),
    founderName: "",
    location: "",
    jd_id: "",
  };
  const [formData, setFormData] = useState(formDataInitialState);
  const [draftId, setDraftId] = useState(null);
  const [wallet, setWallet] = useState({ Coins: 0, Hold: 0, Spent: 0 });
  const [experiencePrice, setExperiencePrice] = useState([]);
  const [coinPrice, setCoinPrice] = useState(null);
  const [skillPrice, setSkillPrice] = useState(null);

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const params = new URLSearchParams(search);
  const inviteId = params.get("inviteId");
  const companyId = params.get("companyId");

  const openJobForm = () => setIsJobFormOpen(true);
  const closeJobForm = () => {
    setIsJobFormOpen(false); 
    setFormData(formDataInitialState);
  };
  const openDraftModal = () => setIsDraftsOpen(true);
  const closeDraftModal = () => {
    setIsDraftsOpen(false);
    setFormData(formDataInitialState);
  };

  const handleAuthStateChanged = async (user) => {
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      const { dob, phone, gender, licences } = idTokenResult.claims;
      user.claims = {
        dob,
        phone,
        gender,
        licences: licences || []
      };
      mixpanel.identify();
      mixpanel.people.set({
        email: user.email,
      });
      if (idTokenResult.claims.admin) {
        user.claims.admin = true;
      }
      if (idTokenResult.claims.superAdmin) {
        user.claims.superAdmin = true;
      }
    }
    setUser(user);
    setInitializing(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    const splitPath = pathname.split("/");
    if (
      initializing ||
      unauthLocations.some((location) => new RegExp(location).test(window.location.pathname))
    )
      return;
    if (!user) {
      if (splitPath[1] === "techyrr") return;
      if (inviteId) {
        if (companyId) {
          navigate(`/admin/signup/${inviteId}?companyId=${companyId}`);
        } else {
          navigate(`/admin/signup/${inviteId}`);
        }
      } else navigate("/signin");
      return;
    }
    const { licences, companyId } = user.claims;
    if (splitPath[1] === "admin") {
      if (licences.includes(splitPath[2]) || ["dashboard", "transactions", "pricing","role-mapping"].includes(splitPath[2])) {
        setAuthorized(true);
      } else {
        setAuthorized(false);
      }
    } else if (splitPath[1] === "techyrr" && licences.includes("v3")) {
      setAuthorized(true);
    }
  }, [user, initializing, pathname]);

  const login = (email, password, techyrrAdmin = false) => {
    return new Promise((resolve, reject) => {
      const loginAsync = async () => {
        try {
          trackEvent("login", { email });
          const user = await signInWithEmailAndPassword(auth, email, password);
          resolve(user);
        } catch (error) {
          console.log("Error logging in:", error);
          reject(error);
        }
      };
      loginAsync();
    });
  };

  const signup = (
    displayName,
    gender,
    phone,
    dob,
    email,
    password,
    company = "techyrr",
    techyrrAdmin = false
  ) => {
    return new Promise((resolve, reject) => {
      try {
        trackEvent("signup", { email });
        const functions = getFunctions();
        const cloudSignup = httpsCallable(functions, "cloudSignup");
        const signupData = {
          email: email,
          password: password,
          displayName: displayName,
          customClaims: {
            gender: gender,
            phone: phone,
          },
        };
        if (techyrrAdmin) {
          signupData.customClaims.techyrrAdmin = true;
          signupData.customClaims.admin = true;
          signupData.customClaims.company = company;
          signupData.customClaims.companyId = techyrrCompanyId;
        }
        cloudSignup(signupData)
          .then(async (res) => {
            console.log(res, "by cloud signup");
            if (res.data.success) {
              trackEvent("login", { email });
              const user = await signInWithEmailAndPassword(auth, email, password);
              resolve(user);
            } else {
              console.log("Error signing up: ", res.data.error);
              reject(res.data.error);
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  const resetpassword = async (email, setEmail) => {
    return new Promise((resolve, reject) => {
      const resetpasswordAsync = async () => {
        setEmail((state) => ({ ...state, sending: true }));
        try {
          await sendPasswordResetEmail(auth, email);
          setEmail((state) => ({
            ...state,
            address: email,
            sent: true,
            sending: false,
            error: false,
            errorCode: "",
          }));
          resolve();
        } catch (error) {
          setEmail((state) => ({
            ...state,
            error: true,
            sending: false,
            errorCode: error.code,
          })); 
          console.log(error);
          reject(error);
        }
      };
      resetpasswordAsync();
    });
  };

  const signout = () => {
    return new Promise((resolve, reject) => {
      const signoutAsync = async () => {
        try {
          await signOut(auth);
          resolve();
        } catch (error) {
          reject(error);
        }
      };
      signoutAsync();
    });
  };

  const deleteAccount = () => {
    return new Promise((resolve, reject) => {
      const deleteAccountAsync = async () => {
        try {
          const res = await deleteUser(user);
          resolve(res);
        } catch (error) {
          console.log("Error deleting user:", error);
          reject(error);
        }
      };
      deleteAccountAsync();
    });
  };

  const reauthenticate = (email, password) => {
    return new Promise((resolve, reject) => {
      const reauthenticateAsync = async () => {
        try {
          const credential = EmailAuthProvider.credential(email, password);
          const res = await reauthenticateWithCredential(user, credential);
          resolve(res);
        } catch (error) {
          console.log("Error reauthenticating user:", error);
          reject(error);
        }
      };
      reauthenticateAsync();
    });
  };

  const getCustomTokenFromBackend = async (uid) => {
    try {
      const response = await fetch(`${BASE_URL}get-custom-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
        },
        body: JSON.stringify({ uid: uid }),
      });
      const data = await response.json();
      if (data.success) {
        return data.token;
      } else {
        throw new Error("Failed to fetch custom token");
      }
    } catch (error) {
      console.error("Error fetching custom token:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        initializing,
        login,
        signup,
        resetpassword,
        signout,
        deleteAccount,
        reauthenticate,
        getCustomTokenFromBackend,
        isJobFormOpen, 
        openJobForm,
        closeJobForm,
        formData,
        setFormData,
        isDraftsOpen,
        openDraftModal,
        closeDraftModal,
        draftId,
        setDraftId,
        useDefaultTemplates,
        setUseDefaultTemplates,
        authorized,
        setAuthorized,
        wallet,
        setWallet,
        experiencePrice,
        setExperiencePrice,
        coinPrice,
        setCoinPrice,
        skillPrice,
        setSkillPrice,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
