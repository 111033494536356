import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isComponentAllowed } from "lib/utilities/componentAccess";

const ProtectedRoute = ({ component, uniqueName }) => {
  if (isComponentAllowed(uniqueName)) {
    return component;
  }

  toast.error("You do not have access to this page", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  return <Navigate to="/not-found" />;
};

export default ProtectedRoute;
