import { Close, PersonAdd, Send } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { BASE_URL, restrictedDomains, emailRegex } from "lib/utilities/globalConstants";
import { tokenResult } from "lib/utilities/tokenResult";
import { UserAuth } from "provider/AuthProvider";
import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

export default function InviteCompanyAdmin() {
    const [formData, setFormData] = useState({
        email: "",
        roles: [] // Only store role IDs here
    });
    const [roles, setRoles] = useState([]);
    const [open, setOpen] = useState(false);
    const { user } = UserAuth();

    const fetchRoles = useCallback(async () => {
        try {
            const idTokenResult = await tokenResult(user);
            const response = await fetch(`${BASE_URL}admin/getRoles`, {
                headers: {
                    Authorization: `Bearer ${idTokenResult.token}`
                }
            });
            const data = await response.json();

            if (response.ok) {
                setRoles(data.roles);
            } else {
                toast.error("Failed to fetch roles");
            }
        } catch (error) {
            console.error("Error fetching roles:", error);
            toast.error("Error fetching roles. Please try again.");
        }
    }, [user]);

    useEffect(() => {
        if (open) fetchRoles();
    }, [open, fetchRoles]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const isValidEmail = (email) => {
        if (!emailRegex.test(email)) {
            return false;
        }
        const domain = email.split("@")[1];
        return !restrictedDomains.includes(domain);
    };

    const sendInvite = async () => {
        if (!user) {
            toast.error("Error, please sign in first");
            return;
        }

        if (!formData.email || !formData.roles.length) {
            toast.error("Please fill in all required fields");
            return;
        }

        if (!isValidEmail(formData.email)) {
            toast.error("Invalid email domain. Please use a different email.");
            return;
        }

        try {
            const idTokenResult = await tokenResult(user);

            const licences = idTokenResult.claims.licences;

            const response = await fetch(`${BASE_URL}admin/invite`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${idTokenResult.token}`
                },
                body: JSON.stringify({ ...formData, licences, user })
            });

            const data = await response.json();

            if (response.ok) {
                toast.success(data.message);
                handleClose();
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            console.error("Error occurred:", error);
            toast.error("Something went wrong. Please try again.");
        }
    };

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setFormData({ email: "", roles: [] });
    };

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="primary"
                startIcon={<PersonAdd />}
                style={{ color: "white" }}
                sx={{
                    width: "200px",
                    borderRadius: 2,
                    textTransform: "none",
                    boxShadow: 2,
                    "&:hover": {
                        boxShadow: 4
                    }
                }}
                onClick={handleClickOpen}
            >
                Invite User
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        boxShadow: 24
                    }
                }}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: 1,
                        borderColor: "divider",
                        pb: 2
                    }}
                >
                    <Typography variant="h6" component="div" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <PersonAdd color="primary" />
                        Invite New Admin
                    </Typography>
                    <IconButton onClick={handleClose} size="small">
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ mt: 2 }}>
                    <DialogContentText sx={{ mb: 3 }}>
                        Please fill in the details below to send an admin invitation.
                    </DialogContentText>

                    <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                        <TextField
                            required
                            fullWidth
                            label="Email Address"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            variant="outlined"
                            placeholder="Enter business email address"
                            helperText="Personal email domains are not allowed"
                        />

                        <FormControl fullWidth>
                            <InputLabel id="roles-label">Roles</InputLabel>
                            <Select
                                required
                                labelId="roles-label"
                                name="roles"
                                multiple
                                value={formData.roles.map((id) => roles.find((role) => role.id === id)?.role_name || "")}
                                onChange={(e) => {
                                    const selectedRoleNames = e.target.value;
                                    setFormData((prev) => ({
                                        ...prev,
                                        roles: selectedRoleNames
                                            .map((roleName) => {
                                                const role = roles.find((r) => r.role_name === roleName);
                                                return role ? role.id : null;
                                            })
                                            .filter(Boolean)
                                    }));
                                }}
                                label="Roles"
                                style={{ height: "44px" }}
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role.id} value={role.role_name}>
                                        {role.role_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>

                <DialogActions
                    sx={{
                        p: 3,
                        borderTop: 1,
                        borderColor: "divider",
                        gap: 1
                    }}
                >
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        style={{ color: "white", background: "rgb(255 73 73)" }}
                        startIcon={<Close />}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={sendInvite}
                        variant="contained"
                        style={{ color: "white" }}
                        startIcon={<Send />}
                        sx={{
                            minWidth: "120px"
                        }}
                    >
                        Send Invite
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
