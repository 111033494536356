import React from "react";
import { Link } from "react-router-dom";
import { useBreadcrumbs } from "provider/BreadcrumbProvider";
import { ChevronRight, Home } from "@mui/icons-material";

const AdminNavbar = () => {
  const { breadcrumbs } = useBreadcrumbs();

  return (
    <nav className="bg-white border-b border-gray-200 h-11 sticky top-0 z-50 flex justify-between px-3 shadow-sm">
      <ol className="flex items-center">
        <li className="flex items-center">
          <Link
            to="/admin/dashboard"
            className="p-2 rounded-lg text-gray-500 hover:text-blue-600 hover:bg-blue-50 transition-colors duration-200 flex items-center"
            aria-label="Home"
          >
            <Home className="w-[20px] h-[20px]" />
          </Link>
          <ChevronRight className="w-4 h-4 text-gray-700" />
        </li>
        {breadcrumbs?.map((path, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return (
            <li key={index} className="flex items-center min-w-0">
              {!isLast ? (
                <>
                  <Link
                    to={path.link}
                    className="px-2.5 py-1.5 rounded-md text-gray-600 hover:text-blue-600 hover:bg-blue-50 transition-colors duration-200 text-sm font-medium flex items-center"
                  >
                    <span className="truncate max-w-[100px]">
                      {path.label}
                    </span>
                  </Link>
                  <ChevronRight className="w-4 h-4 text-gray-700" />
                </>
              ) : (
                  <span className="px-2.5 py-1.5 bg-gray-50 rounded-md text-gray-900 text-sm font-semibold flex">
                    <span className="truncate max-w-[100px]">
                      {path.label}
                    </span>
                  </span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default AdminNavbar;
