/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

// Material Kit 2 React example components

// Material Kit 2 React page layout routes

// Images
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

function SignupComponent({
  techyrrAdmin,
  handleSignup,
  handleGotoSignIn = null,
  credentials,
  onChange,
  creatingAccount,
}) {
  const { origin } = useParams();

  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        px={1}
        pt={10}
        pb={5}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
          sx={{
            overflowY: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          pt={2}
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white">
                  {origin === "techyrr" ? (techyrrAdmin ? "Recruiter " : "Student ") : ""}Sign up
                </MKTypography>
                {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                    <Grid item xs={2}>
                      <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                        <FacebookIcon color="inherit" />
                      </MKTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                        <GitHubIcon color="inherit" />
                      </MKTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                        <GoogleIcon color="inherit" />
                      </MKTypography>
                    </Grid>
                  </Grid> */}
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox>
                    <MKInput
                      type="text"
                      onChange={onChange}
                      value={credentials.fullName}
                      label="Full Name"
                      name="fullName"
                      fullWidth
                    />
                  </MKBox>
                  {/* {techyrrAdmin && (
                    <MKBox mt={2}>
                      <MKInput
                        type="text"
                        onChange={onChange}
                        value={credentials.company}
                        label="Company Name"
                        name="company"
                        fullWidth
                      />
                    </MKBox>
                  )} */}
                  <MKBox mb={1} mt={1}>
                    <RadioGroup
                      sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
                      row
                      name="gender"
                      value={credentials.gender}
                      onChange={onChange}
                    >
                      <FormControlLabel
                        sx={{ "& .MuiFormControlLabel-label": { fontWeight: "normal" } }}
                        value="Male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        sx={{ "& .MuiFormControlLabel-label": { fontWeight: "normal" } }}
                        value="Female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        sx={{ "& .MuiFormControlLabel-label": { fontWeight: "normal" } }}
                        value="Other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="tel"
                      label="Phone Number"
                      name="phone"
                      value={credentials.phone}
                      onChange={onChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="email"
                      label="Email"
                      name="email"
                      value={credentials.email}
                      onChange={onChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Password"
                      name="password"
                      value={credentials.password}
                      onChange={onChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      onClick={handleSignup}
                      disabled={creatingAccount}
                      fullWidth
                    >
                      {creatingAccount ? "loading..." : "sign up"}
                    </MKButton>
                  </MKBox>
                  {handleGotoSignIn && (
                    <MKBox mt={3} mb={1} textAlign="center">
                      <MKTypography variant="button" color="text">
                        Already have an account?{" "}
                        <MKTypography
                          onClick={handleGotoSignIn}
                          variant="button"
                          color="info"
                          fontWeight="medium"
                          textGradient
                          sx={{ cursor: "pointer" }}
                        >
                          Sign In
                        </MKTypography>
                      </MKTypography>
                    </MKBox>
                  )}
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

SignupComponent.propTypes = {
  techyrrAdmin: PropTypes.bool,
  handleSignup: PropTypes.func.isRequired,
  handleGotoSignIn: PropTypes.func.isRequired,
  credentials: PropTypes.shape({
    fullName: PropTypes.string,
    gender: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    company: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  creatingAccount: PropTypes.bool.isRequired,
};

export default SignupComponent;
