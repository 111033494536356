import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { BASE_URL } from "lib/utilities/globalConstants";
import { tokenResult } from "lib/utilities/tokenResult";
import { UserAuth } from "provider/AuthProvider";
import InviteCompanyAdmin from "customComponents/Modals/InviteCompanyAdmins";

const AdminTablePage = () => {
  const { user } = UserAuth();
  const [admins, setAdmins] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const idTokenResult = await tokenResult(user);
        const token = idTokenResult.token;

        const response = await fetch(`${BASE_URL}admin/getAllAdminData`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setAdmins(data?.admins || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchAdminData();
  }, [user]);

  return (
    <div className="container" style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: 0 }}>Admin Details</h2>
        <InviteCompanyAdmin />
      </div>

      {loadingData ? (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper} style={{ border: "1px solid #ccc", borderRadius: "8px" }}>
          <Table style={{ minWidth: "600px" }}>
            <TableHead style={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", border: "1px solid #ddd" }}>Admin Name</TableCell>
                <TableCell style={{ fontWeight: "bold", border: "1px solid #ddd" }}>Admin Email</TableCell>
                <TableCell style={{ fontWeight: "bold", border: "1px solid #ddd" }}>Created On</TableCell>
                <TableCell style={{ fontWeight: "bold", border: "1px solid #ddd" }}>Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins.map((admin) => (
                <TableRow key={admin.admin_id} style={{ borderBottom: "1px solid #ddd" }}>
                  <TableCell style={{ border: "1px solid #ddd" }}>{admin.user_name}</TableCell>
                  <TableCell style={{ border: "1px solid #ddd" }}>{admin.user_email}</TableCell> 
                  <TableCell style={{ border: "1px solid #ddd" }}>{new Date(admin.created_at).toLocaleString()}</TableCell>
                  <TableCell style={{ border: "1px solid #ddd" }}>{admin.role_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default AdminTablePage;
