import { useParams, useSearchParams } from "react-router-dom";
import AdminSignup from "../../../../pages/Admin/Signup/index";
import { techyrrInviteId } from "lib/utilities/globalConstants";
import { techyrrCompanyId } from "lib/utilities/globalConstants";

function AdminSignupPage({ techyrrAdmin }) {
  let inviteId = useParams().inviteId;
  const [searchParams] = useSearchParams();
  let companyId = searchParams?.get("companyId") || null; 

  if (techyrrAdmin) {
    inviteId = techyrrInviteId;
    companyId = techyrrCompanyId;
  }

  return (
    <div>
      <AdminSignup inviteId={inviteId} companyId={companyId} techyrrAdmin={techyrrAdmin} />
    </div>
  );
}

export default AdminSignupPage;
