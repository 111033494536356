/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Suspense, useEffect } from "react";
import { lazy } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import ConfigWrapper from "customComponents/ConfigWrapper";
import AdminLayout from "layouts/pages/admin";
import AdminSignupPage from "layouts/pages/admin/signup";
import ForgotPasswordPage from "layouts/pages/authentication/forgot-password";
import SignInPage from "layouts/pages/authentication/sign-in";
import SignUpPage from "layouts/pages/authentication/sign-up";
import CookiePolicyPage from "layouts/pages/landing-pages/cookie-policy";
import DataPrivacyPolicyPage from "layouts/pages/landing-pages/data-privacy-policy";
import DataRetentionPolicyPage from "layouts/pages/landing-pages/data-retention-policy";
import DataSubjectRightsPolicyPage from "layouts/pages/landing-pages/data-subject-rights-policy";
import SuperAdminLayout from "layouts/pages/super-admin";
import ApproveCustomSkills from "pages/SuperAdmin/ApproveCustomSkills";
import { AuthProvider } from "provider/AuthProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routes from "webRoutes";
import "./index.css";
import SuperDashboard from "./pages/SuperAdmin/Dashboard/index";
import AdminHome from "pages/Admin/AdminHome";
import { CircularProgress } from "@mui/material";
import DashboardLayout from "layouts/pages/admin/dashboardLayout";
import { BreadcrumbProvider } from "provider/BreadcrumbProvider";
import AdminPricing from "pages/Admin/Pricing";
import { defaultToastConfig } from "lib/utilities/taostConfig";
import TransactionHistory from "pages/Admin/TransactionHistory";
import AdminTablePage from "customComponents/AdminComponents/RoleMapping";
import ProtectedRoute from "./provider/ProtectedRoute";
import NotAuthorized from "pages/NotAuthorized";


const CandidatePageV1 = lazy(() => import("pages/Admin/V1/Candidate"));
const AdminDashboardV1 = lazy(() => import("pages/Admin/V1/Dashboard"));
const FormPageV1 = lazy(() => import("pages/Admin/V1/FormPage"));
const CandidatePageV2 = lazy(() => import("pages/Admin/V2/Candidate"));
const AdminDashboardV2 = lazy(() => import("pages/Admin/V2/Dashboard"));
const FormPageV2 = lazy(() => import("pages/Admin/V2/FormPage"));
const UploadResume = lazy(() => import("pages/Admin/V3/UploadResume"));

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.route}
            uniqueName={route.uniqueName || `UNIQUE_NAME_${route.route}`}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <BreadcrumbProvider>
        <ToastContainer {...defaultToastConfig} />
        <AuthProvider>
          <CssBaseline />
          <Routes>
            {getRoutes(routes)}
            <Route
              path="/signin/:origin?"
              element={<ConfigWrapper Component={<SignInPage />} />}
            />
            <Route
              path="/signup/:origin?"
              element={<ConfigWrapper Component={<SignUpPage />} />}
            />
            <Route
              path="/forgot-password/:origin?"
              element={<ConfigWrapper Component={<ForgotPasswordPage />} />}
            />
            <Route
              path="/techyrr-admin/signup/:origin?"
              element={<ConfigWrapper Component={<SignUpPage techyrrAdmin={true} />} />}
            />
            <Route
              path="/techyrr-admin/signin/:origin?"
              element={<ConfigWrapper Component={<SignInPage techyrrAdmin={true} />} />}
            />
            <Route
              path="/techyrr-admin/forgot-password/:origin?"
              element={<ConfigWrapper Component={<ForgotPasswordPage techyrrAdmin={true} />} />}
            />
            <Route
              path="/admin/role-mapping"
              element={
                <ProtectedRoute
                  uniqueName="RoleMapping_Page"
                  component={<AdminLayout component={<AdminTablePage />} />}
                />
              }
            />
            <Route
              path="/"
              element={<Presentation />}
            />
            <Route
              path="/not-found"
              element={<NotAuthorized />}
            />
            <Route
              path="*"
              element={<Navigate to="/presentation" />}
            />
            <Route
              path="/admin/signup/:inviteId"
              element={<AdminSignupPage />}
            />
            {/* Super Admin Routes */}
            <Route
              path="/super-admin/dashboard"
              element={<SuperAdminLayout component={<SuperDashboard />} />}
            />
            <Route
              path="/super-admin/approve-custom-skills/:companyId/:formId"
              element={<SuperAdminLayout component={<ApproveCustomSkills />} />}
            />
            {/* Admin Routes */}
            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute
                  uniqueName="AdminDashboard_Page"
                  component={<AdminLayout component={<AdminHome />} />}
                />
              }
            />
            <Route
              path="/admin/transactions"
              element={
                <ProtectedRoute
                  uniqueName="TransactionHistory_Page"
                  component={<AdminLayout component={<TransactionHistory />} />}
                />
              }
            />
            <Route
              path="/admin/pricing"
              element={
                <ProtectedRoute
                  uniqueName="AdminPricing_Page"
                  component={<AdminLayout component={<AdminPricing />} />}
                />
              }
            />
            {/* Admin V1 Routes */}
            <Route
              path="/admin/v1/dashboard"
              element={
                <ProtectedRoute
                  uniqueName="AdminV1Dashboard_Page"
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <AdminLayout component={<DashboardLayout component={<AdminDashboardV1 />} />} />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/admin/v1/dashboard/form/:formId"
              element={
                <ProtectedRoute
                  uniqueName="AdminV1Form_Page"
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <AdminLayout component={<DashboardLayout component={<FormPageV1 />} />} />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/admin/v1/dashboard/form/:formId/candidate/:candidateId"
              element={
                <ProtectedRoute
                  uniqueName="AdminV1Candidate_Page"
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <AdminLayout component={<DashboardLayout component={<CandidatePageV1 />} />} />
                    </Suspense>
                  }
                />
              }
            />
            {/* Admin V2 Routes */}
            <Route
              path="/admin/v2/dashboard"
              element={
                <ProtectedRoute
                  uniqueName="AdminV2Dashboard_Page"
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <AdminLayout component={<DashboardLayout component={<AdminDashboardV2 />} />} />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/admin/v2/dashboard/form/:formId"
              element={
                <ProtectedRoute
                  uniqueName="AdminV2Form_Page"
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <AdminLayout component={<DashboardLayout component={<FormPageV2 />} />} />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/admin/v2/dashboard/form/:formId/candidate/:candidateId"
              element={
                <ProtectedRoute
                  uniqueName="AdminV2Candidate_Page"
                  component={
                    <Suspense fallback={<CircularProgress />}>
                      <AdminLayout component={<DashboardLayout component={<CandidatePageV2 />} />} />
                    </Suspense>
                  }
                />
              }
            />
            {/* V3 Routes */}
            <Route
              path="/techyrr/upload-resume"
              element={
                <ProtectedRoute
                  uniqueName="V3UploadResume_Page"
                  component={<AdminLayout component={<UploadResume />} loadFullLayout={false} />}
                />
              }
            />
            <Route
              path="/techyrr/signin"
              element={<SignInPage techyrrAdmin={true} />}
            />
            <Route
              path="/techyrr/signup/"
              element={<AdminSignupPage techyrrAdmin={true} />}
            />
            {/* Policy Pages */}
            <Route
              path="/cookie-policy"
              element={<CookiePolicyPage />}
            />
            <Route
              path="/data-privacy-policy"
              element={<DataPrivacyPolicyPage />}
            />
            <Route
              path="/data-retention-policy"
              element={<DataRetentionPolicyPage />}
            />
            <Route
              path="/data-subject-rights-policy"
              element={<DataSubjectRightsPolicyPage />}
            />
          </Routes>
        </AuthProvider>
      </BreadcrumbProvider>
    </ThemeProvider>
  );
}
