import SignupComponent from "customComponents/SignupComponent";
import { constants } from "lib/utilities/Auth";
import { BASE_URL } from "lib/utilities/globalConstants";
// import { BASE_URL } from "lib/utilities/globalConstants";
import { signupSchema } from "lib/utilities/schemas/signupSchema";
import { PropTypes } from "prop-types";
import { AuthContext } from "provider/AuthProvider";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Material Kit 2 React example components
function AdminSignup({ inviteId, companyId, techyrrAdmin }) {
  const { login } = useContext(AuthContext);
  const [credentials, setCredentials] = useState({
    fullName: "",
    gender: "",
    phone: "",
    email: "",
    password: "",
  });

  const [creatingAccount, setCreatingAccount] = useState(false);
  const { regex } = constants;
  const navigate = useNavigate();

  const onChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === "phone") {
      updatedValue = value.replace(regex.bannedSymbols.phoneNumber, "");
    }

    setCredentials({ ...credentials, [name]: updatedValue });
  };

  const handleSignup = async () => {
    const validationResult = signupSchema.safeParse(credentials);
    if (validationResult.success) {
      try {
        setCreatingAccount(true);
        const response = await fetch(`${BASE_URL}admin/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inviteId: inviteId,
            companyId: companyId,
            displayName: credentials.fullName,
            email: credentials.email,
            password: credentials.password,
            gender: credentials.gender,
            phone: credentials.phone,
            techyrrAdmin: techyrrAdmin,
          }),
        });
        const res = await login(credentials.email, credentials.password);
        const adminId = res.user.uid;
        const token = await res.user.getIdToken();

        try {
          const pagesResponse = await fetch(`${BASE_URL}admin/getPagesForAdmin`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ adminId }),
          });

          if (!pagesResponse.ok) {
            throw new Error("Failed to fetch accessible pages");
          }

          const pagesData = await pagesResponse.json();
          localStorage.setItem("accessiblePages", JSON.stringify(pagesData));
        } catch (error) {
          console.error("Error fetching accessible pages:", error);
        }
        const data = await response.json();
        if (response.ok) {
          if (techyrrAdmin) navigate("/techyrr/upload-resume");
          else navigate("/admin/dashboard");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setCreatingAccount(false);
      }

    } else {
      toast.error(
        <p style={{ fontSize: "16px" }}>
          {validationResult.error.errors[0].message || "Try Again"}
        </p>
      );
    }
  };

  return (
    <SignupComponent
      handleSignup={handleSignup}
      credentials={credentials}
      onChange={onChange}
      creatingAccount={creatingAccount}
    />
  );
}

AdminSignup.propTypes = {
  inviteId: PropTypes.string.isRequired,
};

export default AdminSignup;
