import { BASE_URL } from "lib/utilities/globalConstants";

export async function fetchAccessiblePages(user) {
    const adminId = user.uid;
    const token = await user.getIdToken();

    try {
        const pagesResponse = await fetch(`${BASE_URL}admin/getPagesForAdmin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ adminId }),
        });

        if (!pagesResponse.ok) {
            throw new Error("Failed to fetch accessible pages");
        }

        const pagesData = await pagesResponse.json();
        localStorage.setItem("accessiblePages", JSON.stringify(pagesData));
    } catch (error) {
        console.error("Error fetching accessible pages:", error);
    }
}
